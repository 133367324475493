import { SaveMyAllocationComponent } from './components/save-my-allocation/save-my-allocation.component';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { skip, first } from 'rxjs/operators';
import { AppSandbox } from '../../app.sandbox';
import { DataFilters, FieldRangeDates, PlotData, ValueSelect } from 'src/modules/types';
import * as XLSX from 'xlsx';
import { VFMModel } from './../../../types/VFMModel.interface';
import { ALLOCATION_VS_ESTIMATION_LAYOUT, ALLOCATION_VS_ESTIMATION_QGAS, ALLOCATION_VS_ESTIMATION_QOIL, ALLOCATION_VS_ESTIMATION_QWAT, COEFFICENT_LAYOUT, COEFFICENT_PLOT_DATA_QGAS, COEFFICENT_PLOT_DATA_QOIL, COEFFICENT_PLOT_DATA_QWAT, TOTAL_ALLOCATION_DATA_QGAS, TOTAL_ALLOCATION_DATA_QOIL, TOTAL_ALLOCATION_DATA_QWAT, TOTAL_ALLOCATION_LAYOUT } from './components/allocation-chart/allocation-chart.const';
import { MatDialog } from '@angular/material/dialog';
import { SaveMyModelComponent } from '../model';
import { ActivatedRoute, Params } from '@angular/router';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'wag-back-allocation.container',
  template: `

   <h1>Back Allocation module</h1>
   <wag-back-alloction-selection
          [assets]="assets$ | async"
          [fields]="fields$ | async"
          [wells]="wells$ | async"
          [passedData]="passedData"
          (valueSelected)="onValueSelect($event)"
          (allocationFetch)="onAllocationFetch($event)">
   </wag-back-alloction-selection>

   <ng-container *ngIf="allocatedWells.length>0">
   <wag-allocation-settinggs
        [allocatedWells]="this.allocatedWells"
        [minMaxRange]="minMaxRange$ | async"
        [minMaxDateRange]="dateRange$ | async"
        [target1]="targetList"
        [target2]="targetList1"
        [VFMModelsList]="VFMModelsList"
        (allocationDateRangeChange)="onAllocationDateChange($event)"
        [filterData]="filterData"
        (compute)="onClickOnCompute($event)"
        (export)="onClickExport()"
        (save)="onClickSave()">
  </wag-allocation-settinggs>

  <wag-allocation-chart
    [allocationVSEstimationGraphData]="allocationVSEstimationGraphData"
    [allocationVSEstimationLayout]="allocationVSEstimationLayout"
    [coefficientLayout]="coefficientLayout"
    [coefficientGraphData]="coefficientGraphData"
    [totalNewAllocationsGraphData]="totalNewAllocationsGraphData"
    [totalNewAllocationsLayout]="totalNewAllocationsLayout"
    [toogleButtonList]="targetList"
    (clickOnToggle)="clickOnToggle($event)">
  </wag-allocation-chart>
   </ng-container>


  `,
  styleUrls: ['./back-allocation.container.component.scss']
})
export class BackAllocationContainerComponent implements OnInit {
  assets$ = this.sb.assets$;
  fields$ = this.sb.fields$;
  wells$ = this.sb.wells$;
  filterData: DataFilters = { asset:'', field:'', well: '' };
  affiliate!: string;
  targetList:string[]=[];
  targetList1:string[]=[];

  allocatedWells:any[]=[];
  format = "YYYY-MM-DD";

  VFMModelsList:VFMModel[]=[];

  chartData:any[]=[];
  barPlotChart:any;
  exortData:any

  dateRange$: Observable<FieldRangeDates> = this.sb.allocationRangeDates$.pipe(skip(1));
  minMaxRange$: Observable<FieldRangeDates> = this.sb.allocationRangeDates$.pipe(skip(1),first());

  allocationVSEstimationLayout: any = ALLOCATION_VS_ESTIMATION_LAYOUT;
  allocationVSEstimationGraphData!: PlotData[];

  coefficientLayout: any = COEFFICENT_LAYOUT;
  coefficientGraphData!: PlotData[];

  totalNewAllocationsLayout: any = TOTAL_ALLOCATION_LAYOUT;
  totalNewAllocationsGraphData!: PlotData[];

  saveAllocationData:any;
  wellParams:any;
  userName!:string;
  passedData!:any;

  routeSubscription!: Subscription;

  isCatalouge!:boolean

  constructor(private sb: AppSandbox, private dialog: MatDialog, private route: ActivatedRoute,) {
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });
    this.sb.userName$.subscribe(user => { this.userName = user; });


    this.routeSubscription = this.route.queryParams.subscribe((value:Params) => {
      if(Object.keys(value).length>0){
       let decoded = decodeURI(value.tableList);
       let data = JSON.parse(decoded);
       let wells:string[]
       if(data.well_parameters.mapping!=undefined){
        wells=Object.keys(data.well_parameters.mapping);
        this.passedData= Object.assign(Object.assign(data.well_parameters,{'wells':wells}));
        let tempTgt=data.well_parameters.mapping[wells[0]];
        let tgt:string[]=[];
          if(tempTgt!=undefined){
            for (let i=0;i<tempTgt.length;i++){
                tgt.push(tempTgt[i].tgt_s1);
            }
         let array1:string[]=[];
            if(tgt.includes('Qoil')||tgt.includes('Qliq')){
              array1[0]="Qoil"
            }else{
              array1[0]=""
            }
            if(tgt.includes('Qgas') ||tgt.includes('GOR')){
              array1[1]="Qgas"
            }else{
              array1[1]=""
            }
            if(tgt.includes('Qwat') ||tgt.includes('WCT')){
              array1[2]="Qwat"
            }else{
              array1[2]=""
            }
        this.passedData= Object.assign(Object.assign(data.well_parameters,{'tgt':array1}));
          }
       }
      }

    })
  }
  ngOnInit(): void {
    window.history.replaceState({}, '', '/allocation');
  }
  dateRangeSubscription: Subscription = this.dateRange$.subscribe(dateRange => {
   if(this.isCatalouge)
   {
    this.filterData = {
      ...this.filterData,
      date_min: new Date(moment(this.passedData.min_date, 'YYYY-MM-DD').valueOf()),
      date_max: new Date(moment(this.passedData.max_date, 'YYYY-MM-DD').valueOf()),
    };
   }

    else{
      this.filterData = {
        ...this.filterData,
        date_min: new Date(dateRange.startDate),
        date_max: new Date(dateRange.endDate),
      };
    }
  });


  onValueSelect(event: ValueSelect): void {
    if (event.value) {
      switch (event.control) {
        case 'Asset':
          this.filterData.asset = event.value;
          this.sb.loadFields(this.affiliate, event.value).subscribe();
          break;
        case 'Field':
          this.filterData.field = event.value;
          this.sb.loadWells(this.affiliate, event.value, 'producer').subscribe();

          break;
        case 'Well':
          this.filterData.well = event.value;
          break;
        default:
          break;
      }
    }
  }

  onAllocationFetch($event:any): void {
    this.isCatalouge=$event.isCatalouge;
   this.targetList=[];
   this.targetList1=[];
   let targetTypeList:boolean[]=$event.formValue.targetTypeList;
   targetTypeList.forEach((data,i) => {
    if(i===0 && data===true){
      this.targetList.push('Qoil')
      this.targetList1.push('Qliq')
    }
    else if(i===1 && data===true){
      this.targetList.push('Qgas')
      this.targetList1.push('GOR')
    }
    if(i===2 && data===true){
      this.targetList.push('Qwat')
      this.targetList1.push('WCT')
    }
   });


    this.allocatedWells=[]
    let wells:string[]=$event.formValue.Well;

   if(this.isCatalouge){

    this.filterData = {
      ...this.filterData,
      asset: $event.formValue.Asset.toString(),
      field: $event.formValue.Field.toString(),
      well:$event.formValue.Well.toString()
    };

    this.sb.loadFieldRangeDatesForAllocation(this.affiliate, this.filterData);
    this.sb.callVFMMOdelsList(this.affiliate,this.filterData).subscribe((response:any)=>{
     this.VFMModelsList=response;
    })

    let tempWells:any[]=[];
    let index=0;
     for(let key of Object.keys(this.passedData.mapping)){
      tempWells.push({"well_name":key,'enbleQWG': this.passedData.togle[index].enbleQWG,"target":this.passedData.mapping[key] })
      index++;
     }

    this.allocatedWells=tempWells;
   }else{

    this.sb.loadFieldRangeDatesForAllocation(this.affiliate, this.filterData);
    this.sb.callVFMMOdelsList(this.affiliate,this.filterData).subscribe((response:any)=>{
     this.VFMModelsList=response;
    })
    wells.forEach((data:any)=>{
      let targetData:any[]=[];
     this.targetList.forEach(target=>{
       if(target==='Qoil'){
        targetData.push({tgt_s1:target,tbl_s1:'PS_Production_data',col_s1:'Qoil_c_e',tgt_s2:`${target}`+'_ref',tbl_s2:'PS_daily_production_allocation',col_s2:'Qoil_c_d'})
       }
       else if(target==='Qgas'){
        targetData.push({tgt_s1:target,tbl_s1:'PS_Production_data',col_s1:'Qgas_c_e',tgt_s2:`${target}`+'_ref',tbl_s2:'PS_daily_production_allocation',col_s2:'Qgas_c_d'})
       }
       else if(target==='Qwat'){
        targetData.push({tgt_s1:target,tbl_s1:'PS_Production_data',col_s1:'Qwat_c_e',tgt_s2:`${target}`+'_ref',tbl_s2:'PS_daily_production_allocation',col_s2:'Qwat_c_d'})
       }
     })
    let obj={
      well_name:data,
      target:targetData
    }
       this.allocatedWells.push(obj)
    })
   }

  }

  clickOnToggle(event:any){
   this.plotchartData(event.value,event.index);
   this.plotBarchart(event.value,this.barPlotChart);
  }

  onAllocationDateChange(dateRange: FieldRangeDates){
    this.filterData = {
      ...this.filterData,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate),
    };
    this.sb.setAllocationRangeDates(dateRange);
  }

  onClickOnCompute(event:any){
    this.wellParams=undefined;
    this.saveAllocationData=undefined;
     this.allocatedWells=[];
     this.allocatedWells=event.wells_allocation;
    let mappingObject:any={};
    for(let i=0;i< event.wells_allocation.length;i++){
         mappingObject=Object.assign(mappingObject,{[event.wells_allocation[i].well_name]:event.wells_allocation[i].target})
    }
    let mindate:any;
    let maxDate:any;
    if(this.isCatalouge){

    }else{

    }
    let  reqObj:any={
      assets:this.filterData.asset,
      fields:this.filterData.field,
      mapping:mappingObject,
      min_date:moment(this.filterData.date_min).format(this.format),
      max_date:moment(this.filterData.date_max).format(this.format)
    }
    this.wellParams=reqObj;

   this.sb.callBackAllocationApi(this.affiliate,reqObj).subscribe(response=>{


    this.chartData=[];;
    this.barPlotChart=undefined;
    this.exortData=undefined;
    this.exortData=response.data;
    this.chartData=response.data.data;
    this.barPlotChart=response.allocation_sum;
    this.plotchartData(this.targetList[0],0);
    this.plotBarchart(this.targetList[0],this.barPlotChart);
   })

  }

  plotBarchart(tgt:string,allocation_sum:any){
    let wellNameList: string[] = [];
    let oldValuesArray:any[]=[];
    let newValuesArray:any[]=[];
    this.allocatedWells.forEach((data:any,index)=>{
      wellNameList.push(data.well_name);
    });
  for(let key of Object.keys(allocation_sum)){
   if(tgt===key){
     let newValues=allocation_sum[tgt]['new'];
     let oldValues=allocation_sum[tgt]['old'];
      oldValuesArray=Object.values(oldValues);
      newValuesArray=Object.values(newValues);
   }
   }
   if(tgt==='Qoil'){
    this.totalNewAllocationsLayout.title.text='New Allocation Over the period'
    this.totalNewAllocationsGraphData=JSON.parse(JSON.stringify(TOTAL_ALLOCATION_DATA_QOIL));
    this.totalNewAllocationsGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='Total official allocation'){
        plot.y=oldValuesArray
      }else if(plot.name==='Total new allocation'){
        plot.y=newValuesArray
      }
      plot.x=wellNameList
     })

  }else if(tgt==="Qgas"){
    this.totalNewAllocationsLayout.title.text='New Allocation Over the period'
    this.totalNewAllocationsGraphData=JSON.parse(JSON.stringify(TOTAL_ALLOCATION_DATA_QGAS));
    this.totalNewAllocationsGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='Total official allocation'){
        plot.y=oldValuesArray
      }else if(plot.name==='Total new allocation'){
        plot.y=newValuesArray
      }
      plot.x=wellNameList
     })

  }else if(tgt==="Qwat"){
    this.totalNewAllocationsLayout.title.text='New Allocation Over the period'
    this.totalNewAllocationsGraphData=JSON.parse(JSON.stringify(TOTAL_ALLOCATION_DATA_QWAT));
    this.totalNewAllocationsGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='Total official allocation'){
        plot.y=oldValuesArray
      }else if(plot.name==='Total new allocation'){
        plot.y=newValuesArray
      }
      plot.x=wellNameList
     })  }

  }

  onClickExport(){
    this.exportAsExcelFile(this.exortData);
  }
  onClickSave(){

    let toggleTgtArray:any[]=[]
    this.allocatedWells.forEach(data=>{
    let toggleTgt={
      "enbleQWG":data.enbleQWG
    }
    toggleTgtArray.push(toggleTgt)
   })
   let wellPrams=this.wellParams;
   Object.assign(wellPrams,{'togle':toggleTgtArray});

    this.saveAllocationData=undefined;
    this.saveAllocationData={
      model_name:'',
      model_description:'',
      username:this.userName,
      well_parameters:wellPrams
    }

    const dialogRef = this.dialog.open(SaveMyAllocationComponent, {
      panelClass: 'allocation-class-apnnel',
      data: { 'data': this.saveAllocationData},
      disableClose: false
    })
  }

  plotchartData(tgt:string,index:number){

    let filterData:any=this.chartData.map(data=>{
      return Object.keys(data).
      filter((key) => !key.includes('index')).
      reduce((cur, key) => { return Object.assign(cur, { [key]: data[key] })}, {});
    })
    let slectedTgt:string=tgt.toLowerCase();
    let wellNameList: string[] = [];
    let dates:any[]=[];
    let total_estimation:any[]=[];
    let total_allocation:any[]=[];
    let coefficient:any[]=[];
    let coefficient_zero:any[]=[];
       this.allocatedWells.forEach((data:any,index)=>{
       wellNameList.push(data.well_name);
         });


    let sortedJson:any[]=this.sortedJsonByTarget(filterData,wellNameList);

    sortedJson[index].forEach((element:any) => {
    coefficient.push(element[`coefficient_${slectedTgt}`])
    total_estimation.push(element[`total_estimation_${slectedTgt}`]);
    total_allocation.push(element[`total_allocation_${slectedTgt}`]);
    dates.push(element['dates']);
    coefficient_zero.push(1);
   })


   if(tgt==='Qoil'){
    this.coefficientLayout.title.text='Correction Coefficent'
    this.allocationVSEstimationLayout.title.text="Qoil Allocation VS Qoil Estimation";
    this.allocationVSEstimationGraphData=JSON.parse(JSON.stringify(ALLOCATION_VS_ESTIMATION_QOIL));
    this.coefficientGraphData=JSON.parse(JSON.stringify(COEFFICENT_PLOT_DATA_QOIL));
    this.totalNewAllocationsGraphData=JSON.parse(JSON.stringify(TOTAL_ALLOCATION_DATA_QOIL));
    this.allocationVSEstimationGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='total_estimation_qoil'){
        plot.y=total_estimation
      }else if(plot.name==='total_allocation_qoil'){
        plot.y=total_allocation
      }
      plot.x=dates
     })

     this.coefficientGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='Correction Coefficient: Allocation_Qoil/Estimation_Qoil'){
        plot.y=coefficient
      }else{
        plot.y=coefficient_zero;
      }
      plot.x=dates
     })


  }else if(tgt==="Qgas"){

    this.coefficientLayout.title.text='Correction Coefficent'
    this.allocationVSEstimationLayout.title.text="Qgas Allocation VS Qgas Estimation";

    this.allocationVSEstimationGraphData=JSON.parse(JSON.stringify(ALLOCATION_VS_ESTIMATION_QGAS));
    this.coefficientGraphData=JSON.parse(JSON.stringify(COEFFICENT_PLOT_DATA_QGAS));
    this.totalNewAllocationsGraphData=JSON.parse(JSON.stringify(TOTAL_ALLOCATION_DATA_QGAS));

    this.allocationVSEstimationGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='total_estimation_qgas'){
        plot.y=total_estimation
      }else if(plot.name==='total_allocation_qgas'){
        plot.y=total_allocation
      }
      plot.x=dates
     })

     this.coefficientGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='Correction Coefficient: Allocation_Qgas/Estimation_Qgas'){
        plot.y=coefficient
      }else{
        plot.y=coefficient_zero;
      }
      plot.x=dates
     })

  }else if(tgt==="Qwat"){

    this.coefficientLayout.title.text='Correction Coefficent'
    this.allocationVSEstimationLayout.title.text="Qwat Allocation VS Qwat Estimation";

    this.allocationVSEstimationGraphData=JSON.parse(JSON.stringify(ALLOCATION_VS_ESTIMATION_QWAT));
    this.coefficientGraphData=JSON.parse(JSON.stringify(COEFFICENT_PLOT_DATA_QWAT));
    this.totalNewAllocationsGraphData=JSON.parse(JSON.stringify(TOTAL_ALLOCATION_DATA_QWAT));

    this.allocationVSEstimationGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='total_estimation_qwat'){
        plot.y=total_estimation
      }else if(plot.name==='total_allocation_qwat'){
        plot.y=total_allocation
      }
      plot.x=dates
     })

     this.coefficientGraphData.forEach((plot:PlotData,index:number)=>{
      if(plot.name==='Correction Coefficient: Allocation_Qwat/Estimation_Qwat'){
        plot.y=coefficient
      }else{
        plot.y=coefficient_zero;
      }
      plot.x=dates
     })

  }


  }

  preferredOrder(obj: any, order: any) {
    let newObject: any= {};
    for(let i = 0; i < order.length; i++) {
        if(obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]];
        }
    }
    return newObject;
  }

  public exportAsExcelFile(ExceldataFormat: any): void {
 let csvJsonData: any[] = [];
    csvJsonData = ExceldataFormat.data;
    csvJsonData.map(data=>{
      data.dates=moment(data.dates).format('DD/MM/YYYY');
    })
    let filterData:any=csvJsonData.map(data=>{
      return Object.keys(data).
      filter((key) => !key.includes('index')).
      reduce((cur, key) => { return Object.assign(cur, { [key]: data[key] })}, {});
    })



     let firstRowHeader:string[]=[];
       firstRowHeader.push('');
       firstRowHeader.push('Total');

       let wellNameList: string[] = [];
       this.allocatedWells.forEach((data:any,index)=>{
       wellNameList.push(data.well_name);
       firstRowHeader.push(data.well_name);
       firstRowHeader.push('');
       firstRowHeader.push('');
       });
     let sortedJson:any[]=this.sortedJsonByTarget(filterData,wellNameList);

     let wb = XLSX.utils.book_new();
      for (let i = 0; i < this.targetList.length; i++) {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
        let hader1:any=[firstRowHeader];
        let hader2:any=[this.getSecondHeader(this.targetList[i],this.allocatedWells)];
        let hader3:any=[this.getThirdHeader(this.targetList[i],this.allocatedWells)];
        let hader4:any=[this.getFourthHeader(this.targetList[i],this.allocatedWells)];
        XLSX.utils.sheet_add_aoa(worksheet, hader1, { origin: "C1" });
        XLSX.utils.sheet_add_aoa(worksheet, hader2, { origin: "A2" });
        XLSX.utils.sheet_add_aoa(worksheet, hader3, { origin: "A3" });
        XLSX.utils.sheet_add_aoa(worksheet, hader4, { origin: "A4" });
        XLSX.utils.sheet_add_json(worksheet, sortedJson[i], { origin: "A5", skipHeader: true });
        XLSX.utils.book_append_sheet(wb, worksheet, this.targetList[i]);
      }
      let fileName = this.filterData.asset + '_' + this.filterData.field + '_' + moment().format('YYYY-MM-DD')+'.xlsx';
      XLSX.writeFile(wb, fileName);

  }
  getSecondHeader(tgt:any,allcatedWells:any){
    let secondRowHeader:string[]=['Date',`Estimation_${tgt}`,`Allocation_${tgt}`,`Coefficient_${tgt} `];
     allcatedWells.forEach((well:any)=>{
      secondRowHeader.push(`Estimation_${tgt}`,`Allocation_${tgt}_Ref`,`New Allocation`)

    })
   return  secondRowHeader;
  }

  getThirdHeader(tgt:any,allcatedWells:any){
    let thirdRowHeader:string[]=[];
    thirdRowHeader.push('Reference Model',`Sum of Estimation_${tgt} `,`Sum Of Allocation_${tgt}`,`Allocation_${tgt}/Estimation_${tgt} `);
    allcatedWells.forEach((well:any) => {
       well.target.forEach((t:any)=>{
          if(tgt===t.tgt_s1){
            if(t.tbl_s1==='VFM_Models'){
            thirdRowHeader.push('VFM_Model_'+`${t.col_s1.model_name}`)
            }else{
            thirdRowHeader.push(`${t.tbl_s1}`+' : '+`${t.col_s1}`)
            }
            thirdRowHeader.push(`PS_Daily_Production_Allocation; ${t.col_s2}`)
            thirdRowHeader.push('Estimate*Coefficient')
          }

       })
    });

    return thirdRowHeader;
  }

  getFourthHeader(tgt:any,allcatedWells:any){
    let fourthHeader:string[]=[];
    if(tgt=="Qoil"){
      fourthHeader.push('Unit','bbl/d ','bbl/d',' - ')
    }else if(tgt==="Qgas"){
      fourthHeader.push('Unit','kSm3/d','kSm3/d',' - ')
    } else if(tgt=="Qwat"){
      fourthHeader.push('Unit','bbl/d ','bbl/d',' - ')
    }

     allcatedWells.forEach((well:any)=>{
      if(tgt==='Qoil'){
        fourthHeader.push('bbl/d','bbl/d','bbl/d')
     }
      if(tgt==="Qgas"){
        fourthHeader.push('kSm3/d','kSm3/d','kSm3/d')
      }
      if(tgt==="Qwat"){
        fourthHeader.push('bbl/d','bbl/d','bbl/d')
      }

    })
   return  fourthHeader;
  }
  sortedJsonByTarget(data:any,wellList:any):any{
    let temp:any[]=[];
    this.targetList.forEach((tgt:any)=>{
      if(tgt==="Qoil"){
        let arry=this.filterArrayByTarget(data,'Qoil');
        let headerSequence = [
          "dates",
          "total_estimation_qoil",
          "total_allocation_qoil",
          "coefficient_qoil"
        ]
        wellList.forEach((element:any)=>{
          headerSequence.push(`estimation_${element}_${tgt}`,`allocation_${element}_${tgt}_ref`,`new_allocation_${element}_${tgt}`)
        });
        let processedData: any = [];
        arry.forEach((element: any) =>{
          processedData.push(this.preferredOrder(element, headerSequence));
        })
        temp.push(processedData);
      }else if(tgt==="Qgas"){
        let arry=this.filterArrayByTarget(data,'Qgas');
        let headerSequence = [
          "dates",
          "total_estimation_qgas",
          "total_allocation_qgas",
          "coefficient_qgas"
        ]
        wellList.forEach((element:any)=>{
          headerSequence.push(`estimation_${element}_${tgt}`,`allocation_${element}_${tgt}_ref`,`new_allocation_${element}_${tgt}`)
        });
        let processedData: any = [];
        arry.forEach((element: any) =>{
          processedData.push(this.preferredOrder(element, headerSequence));
        })
        temp.push(processedData);

      }else if(tgt==='Qwat'){
        let arry=this.filterArrayByTarget(data,'Qwat');
        let headerSequence = [
          "dates",
          "total_estimation_qwat",
          "total_allocation_qwat",
          "coefficient_qwat"
        ]
        wellList.forEach((element:any)=>{
          headerSequence.push(`estimation_${element}_${tgt}`,`allocation_${element}_${tgt}_ref`,`new_allocation_${element}_${tgt}`)
        });
        let processedData: any = [];
        arry.forEach((element: any) =>{
          processedData.push(this.preferredOrder(element, headerSequence));
        })
        temp.push(processedData);
      }
    })

    return temp;
  }


  filterArrayByTarget( jsonData:any,tgt:string):any{
    let filterData:any=jsonData.map((data:any)=>{
      return Object.keys(data).
      filter((key) => (key.includes(tgt) || key.includes(tgt.toLowerCase())||key.includes('dates'))).
      reduce((cur, key) => { return Object.assign(cur, { [key]: data[key] })}, {});
    })
    return filterData;
  }

}
