import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAccordion } from "@angular/material/expansion";
import {
  requireCheckboxesToBeCheckedValidator
} from 'src/modules/app/tools/requiredCheckboxValidator/requiredCheckboxValidator';
import { FieldRangeDates, MLRunParams, PlotData, ValueSelect } from 'src/modules/types';
import {
  INPUT_FEATURE_LIST_INJECTOR,
  INPUT_FEATURE_LIST_PRODUCER,
  INPUT_SOURCE_LIST, ML_ALGO_LIST, ML_TARGET_SOURCE_INJECTOR, ML_TARGET_SOURCE_INJECTORQGINJ, ML_TARGET_SOURCE_PRODUCER, ML_TRAINING_SET_SIZE, OptimizeList, TARGET_LIST_INJECTOR, TARGET_LIST_PRRODUCER
} from '../machine-learning.const';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { map } from 'rxjs/operators';
@Component({
  selector: 'wag-ml-settings',
  styleUrls: ['./ml-settings.component.scss'],
  template: `
<form [formGroup]="mLRunForm" class="well-filters">
    <div class="select-model-bloc">
        <div class="info-bloc">
            <div class="info-title">1- Select Model Inputs</div>
            <div class="info-text">Choose parameters to use as inputs depending of the source(s) selected</div>
        </div>
        <div class="settings-bloc">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Input Source(s)</mat-label>
                <mat-select required disableOptionCentering panelClass="my-panel-class" formControlName="inputSource" (selectionChange)="onInputSourceClick($event)">
                    <mat-option *ngFor="let input_source of INPUT_SOURCE_LIST" [value]="input_source">{{input_source}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-icon>arrow_forward</mat-icon>
            <div class="checkbox-bloc">
                <div *ngFor="let inputFeature of INPUT_FEATURE_LIST; let i = index" formArrayName="inputFeatures">
                    <mat-checkbox color="warn" [formControlName]="i" [value]="inputFeature">{{inputFeature}} </mat-checkbox>
                </div>
            </div>

            <div class="user-added-container1">
                <div class="left-half">

                    <h3>User Added Features <button mat-icon-button type="button" (click)="togglePanel()"><mat-icon>add_circle</mat-icon></button></h3>
                    <h5 style="line-height: 0px; margin-top: 0px;display: flex; justify-content: center;" matTooltip="Feature in Beta version: pending saving model and stability improvement functionalities, To be used with care" matTooltipPosition="after"><i> Beta Version *</i> </h5>
                </div>
                <div class="right-half">
                    <div *ngFor="let feature of selectedUser; let i=index" formArrayName="ColumnValue">

                        <mat-checkbox color="warn" [formControlName]="i" [value]="feature"> {{feature}} </mat-checkbox>

                    </div>

                </div>
            </div>
            <mat-accordion class="example-headers-align" multi>
                <!-- #enddocregion multi -->
                <mat-expansion-panel [expanded]="panelOpenState" style="margin-top:0px">
                    <div class="user-added-container1">

                        <!-- <div style="margin:10px" *ngFor="let bank of bankMultiCtrl?.value">
                    <mat-checkbox> {{bank.name}}  </mat-checkbox>
                </div> -->

                    </div>
                    <div class="user-added-container">
                        <mat-form-field appearance="outline" floatLabel="always" style="width: 100% !important;">
                            <mat-label>Tmore Table</mat-label>
                            <mat-select disableOptionCentering formControlName="TmoreTable" (selectionChange)="onTableValueChanged($event)">
                                <mat-option *ngFor="let TableList of TmoreTableList " [value]="TableList" >{{TableList}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>Tmore Column</mat-label>
                            <mat-select #selectedValue panelClass="matRole" disableOptionCentering formControlName="TmoreColumn" multiple style="width: 100% !important;">
                                <input matInput type="text" placeholder="search..." (keyup)="onKey($event)" style="width: 100% !important;height:40px !important;padding-left:15px !important">
                                <mat-option *ngFor="let option of selectedPercentageList" [value]="option">{{option[0]}} 
                                <span style="margin-left: 5%;" >{{option[1]}}</span>
                                </mat-option>
                                

                            </mat-select>
                        </mat-form-field>

                        <button type="button" (click)="addValues()" class="primary-btn run-ml-btn" style="margin-right:16px">Add</button>
                        <button type="button" (click)="closeAll()" class="primary-btn run-ml-btn">Cancel</button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>


    <div class="select-model-bloc">
        <div class="info-bloc">
            <div class="info-title">2- Select Model Output</div>
            <div class="info-text">Choose the target(s) and its source</div>
        </div>
        <div class="target-bloc">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Target</mat-label>
                <mat-select formControlName="target" required disableOptionCentering panelClass="my-panel-class">
                    <mat-option *ngFor="let target of TARGET_LIST" [value]="target">{{ target }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-icon>arrow_forward</mat-icon>
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Target Source</mat-label>
                <mat-select formControlName="target_source" required disableOptionCentering panelClass="my-panel-class" (selectionChange)="onSelectionChanged($event)">
                    <mat-option *ngFor="let targetSource of ML_TARGET_SOURCE" [value]="targetSource">{{ targetSource }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-icon *ngIf="enableWellStatus">arrow_forward</mat-icon>
            <mat-icon *ngIf="enableColunmSource && wellTestStatus?.length===0">arrow_forward</mat-icon>
            <ng-container *ngIf="enableWellStatus">
                <mat-form-field *ngIf="wellTestStatus?.length" appearance="outline" floatLabel="always">
                    <mat-label>Well Test Status</mat-label>
                    <mat-select formControlName="wellTestStatus" multiple [compareWith]="compareFunction" disableOptionCentering panelClass="my-panel-class">
                        <mat-option *ngFor="let status of wellTestStatus" [value]="status">{{ status }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <mat-icon *ngIf="wellTestStatus?.length">arrow_forward</mat-icon>
            <!-- <mat-icon *ngIf="wellType==='injector'">arrow_forward</mat-icon> -->


            <ng-container *ngIf="enableColunmSource">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>Colunm Source</mat-label>
                    <mat-select formControlName="column_source" required disableOptionCentering panelClass="my-panel-class">

                        <mat-option *ngFor="let columnList of columSourceList" [value]="columnList">{{columnList}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <div class="myclass" *ngIf="enableWellStatus">
                <mat-checkbox color="warn" (change)="onClickValid($event)" formControlName="validFlagcheck"> Valid Flag Only </mat-checkbox>

            </div>


            <mat-icon *ngIf="tMoreCloumnInfo!==''">arrow_forward</mat-icon>
            <div *ngIf="tMoreCloumnInfo!==''">
                <h4 class="t-more-header">Applicable Tmore values  </h4>
                <h4 class="t-more-lable">{{tMoreCloumnInfo}}</h4>
            </div>
        </div>
    </div>

    <div class="select-model-bloc">
        <div class="info-bloc">
            <div class="info-title">3- Select Algorithm Settings</div>
            <div class="info-text">Choose one algorithm and the data percentage used in training set split</div>
        </div>
        <div class="settings-bloc">
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Algorithm</mat-label>
                <mat-select formControlName="ml_algorithm" required disableOptionCentering panelClass="my-panel-class">
                    <mat-option *ngFor="let ml_algorithm of ML_ALGO_LIST" [value]="ml_algorithm">{{ ml_algorithm }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Optimization</mat-label>
                <mat-select formControlName="optimize" required disableOptionCentering panelClass="my-panel-class">
                    <mat-option *ngFor="let optimize of OptimizeList" [value]="optimize.defaultValue" matTooltip={{optimize.tooltip}} [matTooltipDisabled]="optimize.defaultValue === 'False'" matTooltipPosition="after">{{ optimize.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Training Sample Size</mat-label>
                <mat-select formControlName="ml_training_set_size" required disableOptionCentering panelClass="my-panel-class">
                    <mat-option *ngFor="let ml_training_set_size of ML_TRAINING_SET_SIZE" [value]="ml_training_set_size">{{ ml_training_set_size }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="select-model-bloc">
        <div class="info-bloc">
            <section class="info-title">4- Select Training Period</section>
            <section class="info-text">Define the period used for training the Machine Learning Model</section>
        </div>
        <div class="date-settings-bloc">
            <div class="plus-minus-container">
                <button class="round-button" [style.visibility]="isAdd ? 'visible' : 'hidden'" (click)="addMoreTrainingPeriod()">
                    <mat-icon>add</mat-icon>
                </button>
                <wag-date-filters id="training-date" [dateRange]="dateRange" [minMaxRange]="minMaxRange" (dateRangeChange)="dateRangeChange.emit($event)"></wag-date-filters>

            </div>
            <div class="plus-minus-container" *ngIf="isRemove">
                <button class="round-button" [style.visibility]="isRemove ? 'visible' : 'hidden'" (click)="removeMoreTrainingPeriod()">
                    <mat-icon>remove</mat-icon>
                </button>
                <wag-date-filters id="training-date" [dateRange]="dateRange1" [minMaxRange]="minMaxRange1" (dateRangeChange)="dateRangeChange1.emit($event)"></wag-date-filters>

            </div>
            <div class="training-chart-block">
                <mat-accordion multi="true">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>Show training data selected</mat-expansion-panel-header>
                        <wag-well-graph [data]="trainingDataGraph" [layout]="trainingDataLayout"></wag-well-graph>

                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>Show Feature Selected</mat-expansion-panel-header>
                        <wag-well-graph [data]="featureDataGraph" [layout]="featureDataLayout"></wag-well-graph>

                    </mat-expansion-panel>


                </mat-accordion>
            </div>

        </div>

    </div>

    <div class="select-model-bloc">
        <div class="info-bloc">
            <section class="info-title">5- Select Prediction Period</section>
            <section class="info-text">Define the period used for Machine Learning Model prediction</section>
        </div>
        <div class="settings-bloc">
            <wag-date-filters [dateRange]="inferenceDateRange" [minMaxRange]="inferenceMinMaxRange" (dateRangeChange)="inferenceDateRangeChange.emit($event)"></wag-date-filters>
        </div>
    </div>


    <div class="container">
        <mat-checkbox color="warn" class="matClass" (change)="onClickOptime($event)" formControlName="forceOptime"> Force Optime </mat-checkbox>
        <mat-checkbox color="warn" class="matClass" (change)="onClickthreshold($event)" formControlName="threshold"> Include zero values </mat-checkbox>
        <mat-checkbox color="warn" class="matClass" (change)="onClickLearning($event)" formControlName="computeLearning"> Compute Learning Curves </mat-checkbox>
        <mat-checkbox color="warn" class="matClass" (change)="onClickOutliers($event)" formControlName="outliers"> Remove Outliers </mat-checkbox>

        <button type="submit" class="primary-btn run-ml-btn" (click)="onClickRunML()" [disabled]="mLRunForm.invalid">
            <mat-icon>directions_run</mat-icon>
            <span>Run ML</span>
        </button>
    </div>
</form>
  `
})
export class MlSettingsComponent implements OnInit, OnChanges {
  INPUT_SOURCE_LIST = INPUT_SOURCE_LIST;
  INPUT_FEATURE_LIST = INPUT_FEATURE_LIST_PRODUCER;
  TARGET_LIST = TARGET_LIST_PRRODUCER;
  ML_ALGO_LIST = ML_ALGO_LIST;
  OptimizeList = OptimizeList;
  ML_TRAINING_SET_SIZE = ML_TRAINING_SET_SIZE;
  ML_TARGET_SOURCE = ML_TARGET_SOURCE_PRODUCER;

  targetedValue: any = '';
  targetSourceValue: any = '';
  enableWellStatus: boolean = false;
  chkMyFlag: boolean = false;
  learnigFlag: boolean = true;
  ZeroValue: boolean = false;
  optimeFlag: boolean = false;
  outliersFlag: boolean = true;
  isInjector!: boolean;
  enableColunmSource: boolean = false;
  isAdd: boolean = true;
  isRemove: boolean = false;
  dateRange1HideShow: boolean = false;
  panelOpenState: boolean = false;
  selectedUser: any[] = [];
  resColumnValue: string[] = [];
  tMoreTableValue!: string;
  userAddedFeatures: any;
  userAddedFeturesArray: any[] = [];

  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion;
  @Input() dateRange!: FieldRangeDates | null;
  @Input() minMaxRange!: FieldRangeDates | null;
  @Output() dateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();

  @Input() dateRange1!: FieldRangeDates | null;
  @Input() minMaxRange1!: FieldRangeDates | null;
  @Output() dateRangeChange1: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();

  @Output() clickRunML: EventEmitter<MLRunParams> = new EventEmitter<MLRunParams>();

  @Input() inferenceDateRange!: FieldRangeDates | null;
  @Input() inferenceMinMaxRange!: FieldRangeDates | null;
  @Output() inferenceDateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();
  @Output() clickValidFlag = new EventEmitter();
  @Output() clicklearnigFlag = new EventEmitter();
  @Output() clickZeroValue = new EventEmitter();
  @Output() clickOutliersValue = new EventEmitter();
  @Output() clickOptimeFlagValue = new EventEmitter();
  @Output() valueSelectedSource = new EventEmitter();


  @Input() wellTestStatus!: string[] | null;
  @Input() trainingDataGraph!: PlotData[];
  @Input() trainingDataLayout!: any;
  @Input() featureDataLayout!: any;
  @Input() featureDataGraph!: PlotData[];
  @Input() tMoreCloumnInfo!: string;
  @Input() colunmSourceValue!: string;
  @Input() columSourceList!: any;
  @Input() TmoreTableList!: any;
  @Input() featuresList!: any;
  @Input() percentageList!: any;
  @Output() ClickInputFeature: EventEmitter<any> = new EventEmitter<any>();
  @Output() ClickUserAddedFeature: EventEmitter<any> = new EventEmitter<any>();

  @Input() wellType!: string;

  @Output() targetValueSelected = new EventEmitter<ValueSelect>();
  @Output() tableListSelected = new EventEmitter<ValueSelect>();
  @Output() TableValueSelected = new EventEmitter<ValueSelect>();
  @Output() inputSourceValueSelected = new EventEmitter<ValueSelect>();
  @Output() targetSourceValueSelected = new EventEmitter<ValueSelect>();
  @Output() wellStatusSelected = new EventEmitter<ValueSelect>();
  @Output() ColunmSourceSelected = new EventEmitter<ValueSelect>();
  @Output() clickAddRemovePicker = new EventEmitter<boolean>();
  @Input() passedData: any;
  selectedFeaturesList: any[] = [];
  selectedPercentageList: any[] = [];


  mLRunForm: FormGroup = this.formBuilder.group({
    'inputSource': ['', [Validators.required]],
    'inputFeatures': this.formBuilder.array(this.INPUT_FEATURE_LIST.map(x => !1), requireCheckboxesToBeCheckedValidator()),
    'target': ['Qoil', [Validators.required]],
    'target_source': ['', [Validators.required]],
    'TmoreTable': ['',],
    'TmoreColumn': ['',],
    'ColumnValue': this.formBuilder.array([[''], [''], [''], ['']]),
    'ml_algorithm': ['Linear - Ridge', [Validators.required]],
    'optimize': ['Default Parameters', [Validators.required]],
    'ml_training_set_size': ['0.7', [Validators.required]],
    'column_source': ['', [Validators.required]],
    'wellTestStatus': [],
    'validFlagcheck': [false],
    'computeLearning': [false],
    'threshold': [false],
    'forceOptime': [false],
    'outliers': [true],
  });

  mlRunParams: MLRunParams = {
    target: '',
    inputSource: '',
    inputFeatures: [],
    added_features: [],
    min_date: new Date(),
    max_date: new Date(),
    ml_algorithm: '',
    ml_training_set_size: '',
    wellTestStatus: [],
    inference_min_date: new Date(),
    inference_max_date: new Date(),
    target_source: '',

    //TmoreColumn: [],
    column_source: '',
    log_in_mlflow: 'false',
    validFlagcheck: false,
    computeLearning: false,
    threshold: false,
    well_type: this.wellType,
    optimize: 'False',
    forceOptime: false,
    outliers: true

  };

  constructor(private formBuilder: FormBuilder, private sb: AppSandbox, private snackBar: MatSnackBar) {

    if (this.wellType) {
      if (this.wellType === 'producer') {
        this.isInjector = false;
      } else {
        this.isInjector = true;
      }
    }
    this.sb.selectedRunMLParams$.subscribe(items => {

      if (items == undefined) {
        this.clearMlSettings();
      }
      else {
        this.setRunMLSetting(items)
      }
    })
    this.sb.clearMLSettings$.subscribe(data => {

      if (data) {
        this.clearMlSettings();
      }
    })

  }
  onInputSourceClick(eventVal: any) {
    if (this.wellType === 'producer') {
      if (eventVal.value === 'PDMS') {
        this.TmoreTableList.length = 0
        this.TmoreTableList.push('V_PS_Production_Data');
      } else if (eventVal.value === 'PI') {
        this.TmoreTableList.length = 0
        this.TmoreTableList.push('V_PS_Production_Monitoring');
      }
    }
    if (this.wellType === 'injector') {
      if (eventVal.value === 'PDMS') {
        this.TmoreTableList.length = 0
        this.TmoreTableList.push('V_PS_Injection_Data');
      } else if (eventVal.value === 'PI') {
        this.TmoreTableList.length = 0
        this.TmoreTableList.push('V_PS_Injection_Monitoring');
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedFeaturesList = this.featuresList;
    // this.selectedPercentageList = this.percentageList
    this.selectedPercentageList = Object.entries(this.percentageList);

    if (changes.wellType != undefined) {
      if (changes.wellType.currentValue) {
        if (changes.wellType.currentValue === 'producer') {
          this.isInjector = false;

        } else {
          this.isInjector = true;
        }
      }
    }

    if (this.isInjector && ((this.targetedValue === '' && this.targetSourceValue === '') || (this.targetedValue === null && this.targetSourceValue === null))) {

      this.INPUT_FEATURE_LIST = INPUT_FEATURE_LIST_INJECTOR
      this.TARGET_LIST = TARGET_LIST_INJECTOR
      this.ML_TARGET_SOURCE = ML_TARGET_SOURCE_INJECTOR
    }
    else if (this.isInjector && this.targetedValue == 'Qginj') {

      this.INPUT_FEATURE_LIST = INPUT_FEATURE_LIST_INJECTOR
      this.TARGET_LIST = TARGET_LIST_INJECTOR
      this.ML_TARGET_SOURCE = ML_TARGET_SOURCE_INJECTORQGINJ
    }
    else if (this.isInjector && this.targetedValue == 'Qwinj') {

      this.INPUT_FEATURE_LIST = INPUT_FEATURE_LIST_INJECTOR
      this.TARGET_LIST = TARGET_LIST_INJECTOR
      this.ML_TARGET_SOURCE = ML_TARGET_SOURCE_INJECTOR
    }
    else {

      this.INPUT_FEATURE_LIST = INPUT_FEATURE_LIST_PRODUCER
      this.TARGET_LIST = TARGET_LIST_PRRODUCER
      this.ML_TARGET_SOURCE = ML_TARGET_SOURCE_PRODUCER
    }
  }
  ngOnInit(): void {

    if (this.wellType) {
      if (this.wellType === 'producer') {
        this.isInjector = false;
      } else {
        this.isInjector = true;
      }
    }
    this.enableColunmSource = false;
    this.mLRunForm.controls.column_source.disable();
    this.mLRunForm.controls.computeLearning.setValue(false);
    this.mLRunForm.controls.threshold.setValue(false);
    this.mLRunForm.controls.outliers.setValue(true);
    this.mLRunForm.controls.forceOptime.setValue(false);
    this.mLRunForm.controls['target'].valueChanges.subscribe(values => {
      this.targetedValue = values;
      if (this.targetedValue == "Qginj") {
        this.ML_TARGET_SOURCE = [];
        this.ML_TARGET_SOURCE = ML_TARGET_SOURCE_INJECTORQGINJ;
        this.mLRunForm.controls['column_source'].setValue('');
      }
      else if (this.targetedValue == "Qwinj") {
        this.ML_TARGET_SOURCE = [];
        this.ML_TARGET_SOURCE = ML_TARGET_SOURCE_INJECTOR;
        this.mLRunForm.controls['column_source'].setValue('');
      }
      this.mLRunForm.controls['column_source'].setValue('');
      this.targetValueSelected.emit({ control: 'target', value: values });

    });

    this.mLRunForm.controls['inputSource'].valueChanges.subscribe(values => {

      this.inputSourceValueSelected.emit({ control: 'inputSource', value: values });

    });

    // this.mLRunForm.controls['tmoreTable'].valueChanges.subscribe(values => {

    //   this.tableListSelected.emit({ control: 'tmoreTable', value: values });

    // });

    this.mLRunForm.controls['inputFeatures'].valueChanges.subscribe(values => {

      let resinputFeatures: string[] = [];
      this.mLRunForm.controls['inputFeatures'].value.forEach(
        (value: boolean, index: number) => value && resinputFeatures.push(this.INPUT_FEATURE_LIST[index])
      );
      this.mlRunParams.inputFeatures = resinputFeatures;

      this.ClickInputFeature.emit({
        inputFeatures: resinputFeatures

      });
    });



    this.mLRunForm.controls['ColumnValue'].valueChanges.subscribe(values => {

      let resinputColumnValue: string[] = [];
      this.mLRunForm.controls['ColumnValue'].value.forEach(
        (value: boolean, index: number) => value && resinputColumnValue.push(this.selectedUser[index])
      );
      this.mlRunParams.added_features = resinputColumnValue;
      let tempArray = this.userAddedFeturesArray.filter((item: any) => resinputColumnValue.includes(item.column_name));
      this.ClickUserAddedFeature.emit({
        ColumnValue: tempArray

      });
    });




    this.mLRunForm.controls['target_source'].valueChanges.subscribe(values => {
      this.targetSourceValue = values;
      if (values === "PS Production Test Data") {
        this.enableWellStatus = true;
        this.mLRunForm.controls.validFlagcheck.enable();
        this.mLRunForm.controls.validFlagcheck.setValue(false);
        this.enableColunmSource = false;
        this.mLRunForm.controls['column_source'].setValue('');
        this.mLRunForm.controls.column_source.disable();
      } else {
        this.enableWellStatus = false;
        this.tMoreCloumnInfo = '';
        this.mLRunForm.controls.validFlagcheck.disable();
        this.mLRunForm.controls['column_source'].setValue('');
        this.enableColunmSource = true;
        this.mLRunForm.controls.column_source.enable();
        this.mLRunForm.controls['wellTestStatus'].patchValue('');
      }
      this.targetSourceValueSelected.emit({ control: 'target_source', value: values });
    });


    this.mLRunForm.controls['wellTestStatus'].valueChanges.subscribe(values => {
      if (values != undefined) {
        if (values.length > 0) this.wellStatusSelected.emit({ control: 'wellTestStatus', value: values.toString() });
        else
          this.wellStatusSelected.emit({ control: 'wellTestStatus', value: '' });
      }
    });

    this.mLRunForm.controls['column_source'].valueChanges.subscribe(values => {

      if (values != undefined) {
        this.colunmSourceValue = values;
        this.ColunmSourceSelected.emit({ control: 'column_source', value: values });
      }
    });
  }




  togglePanel() {
    this.panelOpenState = !this.panelOpenState
  }


  closeAll() {
    this.selectedUser = [];
    this.userAddedFeturesArray = [];
    this.mLRunForm.controls['TmoreColumn'].reset();
    this.mLRunForm.controls['TmoreTable'].reset();

  }

  addValues() {
    let userAddedFeatures: any
    this.mLRunForm.controls['ColumnValue'].reset();

    if (this.mLRunForm.controls['TmoreColumn'].value.length <= 4) {

      const selectedUserArray = this.mLRunForm.controls['TmoreColumn'].value;

      let stringOnlyArray: any = [];
      selectedUserArray.forEach((innerArray: any) => {
        innerArray.forEach((item: any) => {
          if (typeof item === 'string') {
            stringOnlyArray.push(item);
          }
        })

      })

      this.selectedUser = stringOnlyArray;


      this.selectedUser.forEach(v => {
        userAddedFeatures = {
          table_name: this.tMoreTableValue,
          column_name: v
        }

        this.userAddedFeturesArray.push(userAddedFeatures);
      })
      // this.mLRunForm.controls['ColumnValue'].reset();
    } else {
      // this.mLRunForm.controls['TmoreColumn'].disable();
      this.snackBar.open('Only 4 Values Can be Selected', '', {
        duration: 1500,
      });
    }

  }

  onClickInputFeature(event: MatCheckboxChange, checkedValue: any): void {

    let resinputFeatures: string[] = [];
    this.mLRunForm.controls['inputFeatures'].value.forEach(
      (value: boolean, index: number) => value && resinputFeatures.push(this.INPUT_FEATURE_LIST[index])
    );
    this.mlRunParams.inputFeatures = resinputFeatures;
    this.ClickInputFeature.emit({
      attribute: event.source.value,
      checked: event.checked,
      inputFeatures: resinputFeatures

    });
  }

  onClickValid(event: MatCheckboxChange): void {

    this.chkMyFlag = event.checked;
    this.clickValidFlag.emit({ checked: event.checked });

  }
  onClickLearning(event: MatCheckboxChange): void {

    this.learnigFlag = event.checked;
    this.clicklearnigFlag.emit({ checked: event.checked });
  }

  onClickthreshold(event: MatCheckboxChange): void {
    this.ZeroValue = event.checked;
    this.clickZeroValue.emit({ checked: event.checked });
  }


  onClickOptime(event: MatCheckboxChange): void {
    this.optimeFlag = event.checked;
    this.clickOptimeFlagValue.emit({ checked: event.checked });
  }
  onClickOutliers(event: MatCheckboxChange): void {
    this.outliersFlag = event.checked;
    this.clickOutliersValue.emit({ checked: event.checked });
  }

  onClickRunML(): void {

    this.learnigFlag = this.mLRunForm.controls.computeLearning.value;
    this.ZeroValue = this.mLRunForm.controls.threshold.value;
    this.chkMyFlag = this.mLRunForm.controls.validFlagcheck.value;
    this.optimeFlag = this.mLRunForm.controls.forceOptime.value;
    this.outliersFlag = this.mLRunForm.controls.outliers.value;

    let res: string[] = [];

    this.mLRunForm.controls['inputFeatures'].value.forEach(
      (value: boolean, index: number) => value && res.push(this.INPUT_FEATURE_LIST[index])
    );
    if (this.mLRunForm.contains('column_source')) {
      this.mlRunParams = this.mLRunForm.value;
    }
    else {

      this.mlRunParams = this.mLRunForm.value;
      this.mlRunParams.column_source = "";
    }
    this.mlRunParams.inputFeatures = res;

    let ress: string[] = [];
    this.mLRunForm.controls['ColumnValue'].value.forEach(
      (value: boolean, index: number) => value && ress.push(this.selectedUser[index])
    );
    this.mlRunParams.added_features = ress;

    this.clickRunML.emit(this.mlRunParams);
  }
  onTableValueChanged(event: any) {
    this.tMoreTableValue = event.value;
    this.TableValueSelected.emit({ control: event.source.ngControl.name.toString(), value: event.value });
  }

  onSelectionChanged(event: any) {

    if (event.value === "PS Production Test Data") {
      this.enableWellStatus = true;
      this.enableColunmSource = false;
      this.mLRunForm.controls['column_source'].setValue('');
      this.mLRunForm.controls.column_source.disable();
      this.mLRunForm.controls.validFlagcheck.enable();
      this.mLRunForm.controls.validFlagcheck.setValue(false);
    }
    else {
      this.enableWellStatus = false;
      this.tMoreCloumnInfo = '';
      this.colunmSourceValue = '';
      this.mLRunForm.controls.validFlagcheck.disable();
      this.mLRunForm.controls['column_source'].setValue('');
      this.enableColunmSource = true;
      this.mLRunForm.controls.column_source.enable();
      this.mLRunForm.controls['wellTestStatus'].patchValue('');

    }

    this.valueSelectedSource.emit({ control: event.source.ngControl.name.toString(), value: event.value });


  }

  setRunMLSetting(mlParams: MLRunParams) {

    let minMax: any = {};
    let minMax1: any = {};

    if (mlParams.min_date1 != undefined && mlParams.max_date1 != undefined) {

      minMax1 = {
        startDate: new Date(mlParams.min_date1).getTime(),
        endDate: new Date(mlParams.max_date1).getTime()
      }
      minMax = {
        startDate: new Date(mlParams.min_date).getTime(),
        endDate: new Date(mlParams.max_date).getTime()

      }

      this.dateRange1 = minMax1;
      this.isAdd = false;
      this.isRemove = true;
      this.clickAddRemovePicker.emit(true);
      this.dateRange1HideShow = true;

    } else {
      minMax = {
        startDate: new Date(mlParams.min_date).getTime(),
        endDate: new Date(mlParams.max_date).getTime()

      }

      this.clickAddRemovePicker.emit(false);
      this.isAdd = true;
      this.isRemove = false;
      this.dateRange1HideShow = false;
    }

    let minMaxInference: FieldRangeDates = {
      startDate: new Date(mlParams.inference_min_date).getTime(),
      endDate: new Date(mlParams.inference_max_date).getTime()
    }

    this.mLRunForm.controls['inputSource'].setValue(mlParams.inputSource);
    this.mLRunForm.controls['target'].setValue(mlParams.target);
    this.mLRunForm.controls['ml_training_set_size'].setValue("" + mlParams.ml_training_set_size);
    this.mLRunForm.controls['ml_algorithm'].setValue(mlParams.ml_algorithm);

    this.dateRange = minMax;
    this.inferenceDateRange = minMaxInference;
    this.mLRunForm.controls['wellTestStatus'].setValue(mlParams.wellTestStatus);

    this.mLRunForm.controls['target_source'].setValue(mlParams.target_source);
    this.mLRunForm.controls['optimize'].setValue(mlParams.optimize);

    if (mlParams.column_source !== '' && mlParams.column_source !== undefined) {
      this.valueSelectedSource.emit({ value: mlParams.target_source });
    }
    ;
    this.mLRunForm.controls['column_source'].setValue(mlParams.column_source);
    if (mlParams.validFlagcheck === true) {
      this.mLRunForm.controls['validFlagcheck'].setValue(true);
    }
    else {
      this.mLRunForm.controls['validFlagcheck'].setValue(false);
    }

    if (mlParams.threshold === true) {
      this.mLRunForm.controls['threshold'].setValue(true);
    }
    else {
      this.mLRunForm.controls['threshold'].setValue(false);
    }

    if (mlParams.computeLearning === true) {
      this.mLRunForm.controls['computeLearning'].setValue(true);
    }
    else {
      this.mLRunForm.controls['computeLearning'].setValue(false);
    }

    if (mlParams.forceOptime === true) {
      this.mLRunForm.controls['forceOptime'].setValue(true);
    }
    else {
      this.mLRunForm.controls['forceOptime'].setValue(false);
    }
    if (mlParams.outliers === true) {
      this.mLRunForm.controls['outliers'].setValue(true);
    }
    else {
      this.mLRunForm.controls['outliers'].setValue(false);
    }


    let indexArray: number[] = [];
    if (this.isInjector) {
      INPUT_FEATURE_LIST_INJECTOR.forEach((data, i) => {
        if (mlParams.inputFeatures != undefined) {
          mlParams.inputFeatures.forEach(el => {
            if (data === el) {
              indexArray.push(i);
            }
          })
        }
      })
    } else {
      INPUT_FEATURE_LIST_PRODUCER.forEach((data, i) => {
        if (mlParams.inputFeatures != undefined) {
          mlParams.inputFeatures.forEach(el => {
            if (data === el) {
              indexArray.push(i);
            }
          })
        }
      })
    }
    const formElement = this.mLRunForm.controls['inputFeatures'] as FormArray
    formElement.controls.forEach(x => x.setValue(false));
    formElement.controls.forEach((ctrl, i) => {
      indexArray.forEach(j => {
        if (i === j) {

          ctrl.setValue(true);

        }
      })
    })

  }

  compareFunction(o1: any, o2: any) {

    return (o1 == o2);
  }
  clearMlSettings() {

    this.mLRunForm.reset();
    this.tMoreCloumnInfo = '';
    this.selectedUser = [];
    this.columSourceList = [];
    this.mLRunForm.controls.column_source.disable();
    this.enableColunmSource = false;
    this.mLRunForm.controls.computeLearning.setValue(false);
    this.mLRunForm.controls.threshold.setValue(false);
    this.mLRunForm.controls.outliers.setValue(true);
    this.mLRunForm.controls.forceOptime.setValue(false);
  }


  addMoreTrainingPeriod() {
    this.isAdd = false;
    this.isRemove = true;
    this.clickAddRemovePicker.emit(true);

    this.dateRange1HideShow = true;
    if (this.minMaxRange1 != null) {
      this.dateRangeChange1.emit(this.minMaxRange1);
    }


  }

  removeMoreTrainingPeriod() {
    this.clickAddRemovePicker.emit(false);
    this.isAdd = true;
    this.isRemove = false;
    this.dateRange1HideShow = false;
    if (this.minMaxRange1 != null)
      this.dateRangeChange1.emit(this.minMaxRange1);
    this.dateRange1 = this.minMaxRange1;

  }

  onKey({ target: { value } }: any) {
    this.selectedFeaturesList = this.search(value);
  }
  search(value: string) {
    let filter = value.toLowerCase();
    return this.featuresList.filter((option: string) => option.toLowerCase().includes(filter));
  }
}


