import { AdditionalLinks, Affiliate } from "src/modules/types";
export const AFFILIATELIST: Affiliate[] = [
  { name: 'Access.BRA', value: 'bra', displayName: 'Brazil', path: "./../../../../assets/flag/brazil.svg" },
  { name: 'Access.AGO', value: 'ago', displayName: 'Angola', path: "./../../../../assets/flag/angola.svg" },
  { name: 'Access.NIGJV', value: 'nigjv', displayName: 'Nigeria-JV', path: "./../../../../assets/flag/nigeria.svg" },
  { name: 'Access.NIGDW', value: 'nigdw', displayName: 'Nigeria-DW', path: "./../../../../assets/flag/nigeria.svg" },
  { name: 'Access.GAB', value: 'gab', displayName: 'Gabon', path: "./../../../../assets/flag/gabon.svg" },
  { name: 'Access.DNK', value: 'dnk', displayName: 'Denmark ', path: "./../../../../assets/flag/denmark.svg" },
  { name: 'Access.QAT', value: 'qat', displayName: 'Qatar ', path: "./../../../../assets/flag/qatar.svg" },
  { name: 'Access.COG', value: 'cog', displayName: 'Congo ', path: "./../../../../assets/flag/congo.svg" },
  { name: 'Access.GBR', value: 'gbr', displayName: 'UK ', path: "./../../../../assets/flag/uk.svg" },
  { name: 'Access.ITA', value: 'ita', displayName: 'Italy ', path: "./../../../../assets/flag/it.svg" },
  { name: 'Access.ARG', value: 'arg', displayName: 'Argentina ', path: "./../../../../assets/flag/ar.svg" },
  { name: 'Access.BRN', value: 'brn', displayName: 'Brunei ', path: "./../../../../assets/flag/bn.svg" },
  { name: 'Access.USA', value: 'usa', displayName: 'United States ', path: "./../../../../assets/flag/us.svg" },
  { name: 'Access.NLD', value: 'nld', displayName: 'Netherlands ', path: "./../../../../assets/flag/nl.svg" },
  { name: 'Access.BOL', value: 'bol', displayName: 'Bolivia ', path: "./../../../../assets/flag/bo.svg" },
  { name: 'Access.ALS', value: 'als', displayName: 'Al Shaheen ', path: "./../../../../assets/flag/qatar.svg" },
  { name: 'Access.TRAIN', value: 'training', displayName: 'Training', path: "./../../../../assets/flag/france.png" },
  { name: 'Access.ADNOCON', value: 'adnocon', displayName: 'ADNOCON', path: "./../../../../assets/flag/uae.png" },
  { name: 'Access.ADNOCOFF', value: 'adnocoff', displayName: 'ADNOCOFF', path: "./../../../../assets/flag/uae.png" },
  { name: 'Access.AUS', value: 'aus', displayName: 'Australia', path: "./../../../../assets/flag/aus.svg" },
];


    export const ADDITIONALLINKS: AdditionalLinks[] = [
        { affiliates: 'Brazil', aff: 'bra', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Brazil', aff: 'bra', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8104/TMOREapp' },
        { affiliates: 'Brazil', aff: 'bra', link_name: 'Affiliate Reservoir Monitoring Page', link: 'https://app.powerbi.com/reportEmbed?reportId=691570f9-8606-4233-9a65-e5bfdb46315e&autoAuth=true&ctid=329e91b0-e21f-48fb-a071-456717ecc28e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWV1cm9wZS1ub3J0aC1iLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9', order: 1 },

        { affiliates: 'Angola', aff: 'ago', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Angola', aff: 'ago', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8105/TMOREapp/' },
        { affiliates: 'Angola', aff: 'ago', link_name: 'Affiliate Reservoir Monitoring Page', link: 'https://app.powerbi.com/groups/me/apps/54922967-cbc8-4b59-972e-7b6b1bdc60c6?ctid=329e91b0-e21f-48fb-a071-456717ecc28e', order: 2 },

        { affiliates: 'Nigeria-JV', aff: 'nigjv', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Nigeria-JV', aff: 'nigjv', link_name: 'Tmore Database', link: 'http://freppau-aptmwp2.main.glb.corp.local:8101/TMOREapp', order: 3 },

        { affiliates: 'Nigeria-DW', aff: 'nigdw', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Nigeria-DW', aff: 'nigdw', link_name: 'Tmore Database', link: 'http://freppau-aptmwp2.main.glb.corp.local:8103/TMOREapp', order: 4 },

        { affiliates: 'Gabon', aff: 'gab', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Gabon', aff: 'gab', link_name: 'Tmore Database', link: 'http://opeppa-wptmwp3.main.glb.corp.local:8104/TMOREapp', order: 5 },

        { affiliates: 'Denmark', aff: 'dnk', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Denmark', aff: 'dnk', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8102/TMOREapp', order: 6 },

        { affiliates: 'Qatar', aff: 'qat', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Qatar', aff: 'qat', link_name: 'Tmore Database', link: 'http://freppau-aptmwp2.main.glb.corp.local:8104/TMOREapp', order: 7 },

        { affiliates: 'Congo', aff: 'cog', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Congo', aff: 'cog', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8102/TMOREapp', order: 8 },

        { affiliates: 'UK', aff: 'gbr', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'UK', aff: 'gbr', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8101/TMOREapp', order: 9 },

        { affiliates: 'Italy', aff: 'ita', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Italy', aff: 'ita', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8103/TMOREapp', order: 10 },

        { affiliates: 'Argentina', aff: 'arg', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Argentina', aff: 'arg', link_name: 'Tmore Database', link: 'http://opeppa-wptmwp3.main.glb.corp.local:8101/TMOREapp', order: 11 },

        { affiliates: 'Brunei', aff: 'brn', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Brunei', aff: 'brn', link_name: 'Tmore Database', link: 'http://freppau-aptmwp1.main.glb.corp.local:8106/TMOREapp', order: 12 },

        { affiliates: 'United States', aff: 'usa', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'United States', aff: 'usa', link_name: 'Tmore Database', link: 'http://opeppa-wptmwp3.main.glb.corp.local:8103/TMOREapp', order: 13 },

        { affiliates: 'Netherlands', aff: 'nld', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Netherlands', aff: 'nld', link_name: 'Tmore Database', link: 'http://opeppa-wptmwp3.main.glb.corp.local:8102/TMOREapp', order: 14 },

        { affiliates: 'Bolivia', aff: 'bol', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Bolivia', aff: 'bol', link_name: 'Tmore Database', link: 'http://freppau-aptmwp2.main.glb.corp.local:8102/TMOREapp', order: 15 },

        { affiliates: 'Al Shaheen', aff: 'als', link_name: 'TDASH User Manual', link: 'https://totalworkplace.sharepoint.com/:f:/r/sites/TDASHWAG-UsersChannel/Documents%20partages/General/User%20Manual?csf=1&web=1&e=C7fdQL' },
        { affiliates: 'Al Shaheen', aff: 'als', link_name: 'Tmore Database', link: 'http://freppau-aptmwp2.main.glb.corp.local:8102/TMOREapp', order: 16 },

    ];
