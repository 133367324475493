import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppSandbox} from '../../../app.sandbox';

export enum MENU_LEVEL {
  ROOT,
  ASSET,
  FIELD,
  WELL
}

export class DynamicFlatNode {
  constructor(
    public item: string,
    public level: MENU_LEVEL,
    public expandable: boolean,
    public icon?: string,
    public route?: string,
  ) {
  }
}

export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);
  affiliate!:string
  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private sb: AppSandbox
  ) {
    this.sb.selectedAffiliate$.subscribe(affiliate=>{this.affiliate=affiliate;})
  }

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(
      change => change.added || change.removed ? this.handleTreeControl(change) : undefined
    );

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  //  This is intentional, needs to be implemented as `this class` implements
  }

  handleTreeControl(change: SelectionChange<DynamicFlatNode>): void {
    if (change.added.length) {
      change.added.forEach(node => this.toggleNode(node, true));
      return;
    }
    if (change.removed.length) {
      change.removed.forEach(node => this.toggleNode(node, false));
    }
  }

  addData(newData: string[], node: DynamicFlatNode, index: number): void {
    if (!newData) {
      return;
    }

    const nodes = newData.map(name => new DynamicFlatNode(
      name,
      node.level + 1,
      node.level < MENU_LEVEL.FIELD,
      undefined,
      this.setMenuRoute(name, node)));
    this.data.splice(index + 1, 0, ...nodes);

    this.dataChange.next(this.data);
  }

  setMenuRoute(routeName: string, parentNode: DynamicFlatNode): string | undefined {
    switch (parentNode.level) {
      case MENU_LEVEL.ROOT:
        return `/asset/${routeName}`;

      case MENU_LEVEL.ASSET:
        return `/asset/${parentNode.item}/field/${routeName}`;

      case MENU_LEVEL.FIELD:
        return `/asset/${(this.data[this.getParentIndex(parentNode)].item)}/field/${parentNode.item}/well/${routeName}`;

      default:
        return;
    }
  }

  getParentIndex(menu: DynamicFlatNode): number {
    const childIndex = this.data.findIndex(node => node.item === menu.item && node.level === menu.level);
    let parentIndex = childIndex - 1;
    while (parentIndex > 0 && this.data[parentIndex].level === menu.level) {
      parentIndex--;
    }
    return parentIndex;
  }

  toggleNode(node: DynamicFlatNode, expand: boolean): void {
    const index = this.data.indexOf(node);

    if (index < 0) return;

    if (expand) {
      switch (node.level) {
        case MENU_LEVEL.ROOT:
          this.sb.loadAssets(this.affiliate).subscribe((assets: string[]) => {
            let tempAssest:string[]=assets.filter(asset=> asset!=="UnKnown")
            this.addData(tempAssest, node, index)
          });
          break;
        case MENU_LEVEL.ASSET:
          this.sb.loadFields(this.affiliate,node.item).subscribe((fields: string[]) => this.addData(fields, node, index));
          break;
        case MENU_LEVEL.FIELD:
          this.sb.loadWells(this.affiliate,node.item).subscribe((wells: string[]) => this.addData(wells, node, index));
          break;

        default:
          break;
      }
    } else {
      let count = 0;
      for (
        let i = index + 1;
        i < this.data.length && this.data[i].level > node.level;
        i++, count++
      ) {
      }
      this.data.splice(index + 1, count);
    }

    this.dataChange.next(this.data);
  }
}
